<template>
  <div class="music-player">
    <div class="player-wrap">
      <div class="left">
        <div>
          <img class="img" :src="$store.state.musicInfo.image ? $store.state.musicInfo.image : $store.state.musicInfo.images" alt="">
        </div>
        <div class="info">
          <div class="title">{{ $store.state.musicInfo.title }}</div>
          <div class="des">{{ $store.state.musicInfo.singer }}</div>
        </div>
      </div>
      <div class="audio">
        <div class="musicPlay" v-if="audioVisible">
          <audio ref="audio" @play="playFunc" @pause="pauseFunc" @timeupdate="timeupdateFunc" @canplay="canplayFunc"
                 @error="errorFunc" :src="$store.state.musicInfo.url">
          </audio>
          <div class="audioControl">
            <div class="playControl">
              <div class="playControlWrap" style="">
								<span class="audioSpan" @click="clickFunc('backward')" style="">
									<Icon name="icon-7key-musicshangyiqu" theme-color="iconColorAll" width="20" height="20">
									</Icon>
								</span>
                <span class="audioSpan" @click="clickFunc('play')" v-show="!isPlaying" style="width: 20px">
									<Icon name="icon-7key-musicplayfill" theme-color="iconColorAll" width="25" height="25">
									</Icon>
								</span>
                <span class="audioSpan" @click="clickFunc('pause')" v-show="isPlaying" style="width: 20px">
									<Icon name="icon-7key-music24gf-pause2" theme-color="iconColorAll" width="24" height="24">
									</Icon>
								</span>
                <span class="audioSpan" @click="clickFunc('forward')" style="width: 10px">
									<Icon name="icon-7key-musicxiayiqu" theme-color="iconColorAll" width="20" height="20">
									</Icon>
								</span>
              </div>
              <div class="errorMask" v-show="isError">{{ errorMsg }}</div>
              <!-- <p class="audioClose" @click="audioVisible = false">x</p> -->
              <p>{{ customerName }}</p>
              <div class="time-wrap" style="" @mousedown="isDraging = true" @mouseup="isDraging = false">
                <div class="time">
                  {{ formatCurrentTime }}
                </div>
                <el-slider v-model="playProcess" class="sliderClass" style="width: 95%" @change="setProcessFunc"
                           :format-tooltip="formatTooltip"></el-slider>
                <div class="time">
                  {{ formatDuration }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!--        <div class="voiceVolWrap">-->
        <!--          <span class="audioVolSpan">-->
        <el-tooltip placement="bottom">
          <div slot="content" style="width: 80px;">
            <el-slider v-model="playVolume" class="sliderVolumClass" :format-tooltip="formatTooltipVol"
                       @change="setVolumeFunc"></el-slider>
          </div>
          <Icon class="shengyin" name="icon-7key-musicshengyin" theme-color="iconColorAll" width="20" height="20">
          </Icon>
        </el-tooltip>
        <!--          </span>-->
        <!--        </div>-->
        <el-popover placement="top" width="400" trigger="click" popper-class="reset-popper-bg-color">
          <div class="history-title">{{ $t("playhistory") }}</div>
          <div class="history-item">

            <div class="item-list" v-for="(item, index) in $store.state.historyMusicList" :key="index">
              <img class="song-img" :src="item.image" alt="">
              <div class="song-title">{{ item.title }}</div>
              <div @click="delHistory(item,index)" class="song-del">
                <Icon class="history" name="icon-7key-musicguanbi" theme-color="iconColorAll"
                      width="10" height="10">
                </Icon>
              </div>
            </div>
          </div>

          <Icon slot="reference" class="history" name="icon-7key-musicround-query_builder-" theme-color="iconColorAll"
                width="20" height="20">
          </Icon>
        </el-popover>
        <!-- <el-popover
            placement="bottom"
            title="标题"
            width="200"
            trigger="click"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
            <el-button slot="reference">click 激活</el-button>
          </el-popover> -->
        <!-- <el-tooltip placement="top">
          <div slot="content" id="history-data" style="width: 200px;">
            <div v-for="item in 30">2222</div>
          </div>
          <Icon class="history" name="icon-7key-musicround-query_builder-" theme-color="iconColorAll"
            width="20" height="20">
          </Icon>
        </el-tooltip> -->

        <!-- <div class="buy">
          购买
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {addPlaylog, myPlaylog} from "@/api/Home"

export default {
  name: "MusicPlayer",
  components: {},
  data() {
    return {
      url: 'https://tiji2021.oss-accelerate.aliyuncs.com/cmp/1660113539.mp3',
      customerName: "", // 父组件传递过来的音频名称
      musicUrl: "",
      isPlaying: false,
      isError: false,
      playProcess: 0,
      playVolume: 50,
      playSpeed: 1,
      totalTimes: "00:00",
      currentTime: "00:00",
      errorMsg: this.$t("audioplayfaild"),
      isDraging: false,
      audioSrc: "https://tiji2021.oss-accelerate.aliyuncs.com/cmp/1660113539.mp3", // 父组件传递的音频链接
      audioVisible: true, // 控制音频组件的显示与隐藏
      auditionDuration: 0
    }
  },
  watch: {
    "$store.state.musicInfo": {
      handler(newValue, oldValue) {
        console.log("音乐信息变化:", newValue);
        if (newValue.playStatus) {
          let t = setTimeout(() => {
            this.clickFunc('play')
            clearTimeout(t)
          }, 100)
        } else {
          this.clickFunc('pause')
        }
      },
      deep: true
    }
  },
  filters: {
    fmtPlaySpeed(pSpeed) {
      return pSpeed.toFixed(1);
    },
  },
  computed: {
    formatDuration() {
      if (typeof this.totalTimes == "number") {
        let fTotal = Math.floor(this.totalTimes);
        let tempMin = Math.floor(fTotal / 60);
        let tempSec = fTotal % 60;
        let min, sec;
        return `${this.toStringFunc(tempMin)}:${this.toStringFunc(tempSec)}`;
      }
      return "00:00";
    },
    formatCurrentTime() {
      if (typeof this.currentTime == "number") {
        let fTotal = Math.floor(this.currentTime);
        let tempMin = Math.floor(fTotal / 60);
        let tempSec = fTotal % 60;
        let min, sec;
        console.log(fTotal)
        if (fTotal > this.auditionDuration && this.auditionDuration != 0) {
          this.playProcess = 0;
          this.isPlaying = false;
          this.$refs.audio.load();
          return "00:00";
        }
        return `${this.toStringFunc(tempMin)}:${this.toStringFunc(tempSec)}`;
      }
      return "00:00";
    },
  },
  created() {
    // this.getHistory()
    console.log(this.$store.state.historyMusicList, 'historyMusicList')
  },
  methods: {
    delHistory(item, index) {
      console.log(item, index, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
      this.$confirm(`${this.$t("confirmDel")}${item.title}？`).then(() => {
        console.log('确定');
        this.$store.state.historyMusicList.splice(index, 1);
      }).catch(() => {
        console.log('取消');
      })
    },
    mousedownFunc() {
      this.isDraging = true;
    },
    mouseupFunc() {
      this.isDraging = false;
    },
    formatTooltip(val) {
      // console.log(this.totalTimes, 'formatTooltip')
      let currTime = Math.floor((val / 100) * this.totalTimes);
      let tempMin = Math.floor(currTime / 60);
      let tempSec = currTime % 60;
      let min, sec;
      return `${this.toStringFunc(tempMin)}:${this.toStringFunc(tempSec)}`;
    },
    formatTooltipVol(vol) {
      return `${vol}`;
    },
    clickFunc(val) {
      console.log('播放状态类型:', val)
      if (val == "play") {
        this.isPlaying = true;
        this.$refs.audio.play();
      } else if (val == "pause") {
        this.isPlaying = false;
        this.$refs.audio.pause();
      } else if (val == "backward") {
        // //加快音频播放速度，速率范围[0.5，2]
        // if (this.playSpeed > 0.5) {
        //   this.playSpeed -= 0.5;
        //   this.$refs.audio.playbackRate = this.playSpeed;
        // }
        console.log('上一首');
        let arr = this.$store.state.historyMusicList
        let c1 = (arr).findIndex((arr) => arr.title == this.$store.state.musicInfo.title);
        console.log(c1, this.$store.state.historyMusicList.length, 'hhhhhhhhhhhhhhhhhh')
        if (c1 == -1) {
          this.$store.state.musicInfo = this.$store.state.historyMusicList[0]
          this.playFunc()
        } else if (c1 == 0) {
          let lenList = this.$store.state.historyMusicList.length - 1
          this.$store.state.musicInfo = this.$store.state.historyMusicList[lenList]
          this.playFunc()
        } else if (c1 <= this.$store.state.historyMusicList.length - 1) {
          console.log('jjjjjjjjjjjjjjjjjjjjjjjj');
          this.$store.state.musicInfo = this.$store.state.historyMusicList[c1 - 1]
          this.playFunc()
        }
      } else if (val == "forward") {
        //减慢音频播放速度，速率范围[0.5，2]
        // if (this.playSpeed < 2) {
        //   this.playSpeed += 0.5;
        //   this.$refs.audio.playbackRate = this.playSpeed;
        // }
        console.log('下一首');
        let arr = this.$store.state.historyMusicList
        let c1 = (arr).findIndex((arr) => arr.title == this.$store.state.musicInfo.title);
        console.log(c1, this.$store.state.historyMusicList.length, 'hhhhhhhhhhhhhhhhhh')
        if (c1 == -1) {
          // this.$store.commit('changeHistoryMusicList',arr.unshift({title: obj.title}))
          this.$store.state.musicInfo = this.$store.state.historyMusicList[0]
          this.playFunc()
        } else if (c1 == this.$store.state.historyMusicList.length - 1) {
          this.$store.state.musicInfo = this.$store.state.historyMusicList[0]
          this.playFunc()
        } else if (c1 < this.$store.state.historyMusicList.length - 1) {
          console.log('jjjjjjjjjjjjjjjjjjjjjjjj');
          this.$store.state.musicInfo = this.$store.state.historyMusicList[c1 + 1]
          this.playFunc()
        }

      } else if (val == "refresh") {
        this.$refs.audio.playbackRate = 1;
        this.playSpeed = 1;
        this.$refs.audio.load();
        this.$refs.audio.play();
      }
    },
    setProcessFunc(val) {
      this.$refs.audio.currentTime = Math.round((val / 100) * this.totalTimes);
      console.log(this.totalTimes, 8888)
      this.$refs.audio.play();
    },
    setVolumeFunc(vol) {
      //设置播放音量，audio音量范围[0,1]
      this.$refs.audio.volume = Math.round((vol / 100) * 10) / 10;
    },
    playFunc(e) {
      console.log("music is playing");
      let obj
      if (this.$store.state.musicInfo) {
        obj = JSON.parse(JSON.stringify(this.$store.state.musicInfo))
      }
      obj.playStatus = true
      this.auditionDuration = obj.auditionDuration
      console.log(obj, 'playFuncccccccccccccccccc')
      let params = {};
      params.musicId = obj.musicId
      // addPlaylog(params).then((res) => {
      //   console.log(res.data);

      // });
      let arr = this.$store.state.historyMusicList
      let c1 = (arr).findIndex((arr) => arr.title == obj.title);
      console.log(c1, 'ccccccccccccccccc')
      if (c1 == -1) {
        // this.$store.commit('changeHistoryMusicList',arr.unshift({title: obj.title}))
        this.$store.state.historyMusicList.unshift({title: obj.title, image: obj.image, url: obj.url})
      }

      console.log(this.$store.state.historyMusicList, 'this.$store.state.historyMusicList')
      this.$store.commit('changeMusicInfo', obj)
      this.isPlaying = false;
    },
    pauseFunc(e) {
      console.log("music is paused");
      let obj
      if (this.$store.state.musicInfo) {
        obj = JSON.parse(JSON.stringify(this.$store.state.musicInfo))
      }
      obj.playStatus = false
      this.$store.commit('changeMusicInfo', obj)
      this.isPlaying = true;
    },
    timeupdateFunc(e) {
      // console.log(e.target.currentTime,',,,,,,,,,,,,,,,,,,,,,,,,,,,,')
      if (!this.isDraging) {
        // console.log(';;;;;;;;;;;;;;;;;;;;;;;;')
        this.playProcess = (e.target.currentTime / this.totalTimes) * 100;
        this.currentTime = e.target.currentTime;
        // console.log(this.currentTime.toFixed(2),'this.playProcess')
        this.$store.state.currentTime = this.currentTime.toFixed(2)
      }
    },
    canplayFunc(e) {
      console.log("music can be played");
      this.isError = false;
      this.$refs.audio.volume = 0.5;
      this.totalTimes = e.target.duration; // 无
      console.log(e.target.duration, 'duration')
    },
    errorFunc(e) {
      console.log("music load error!");
      this.isError = true;
    },
    toStringFunc(param) {
      if (param > 9) {
        return param + "";
      } else {
        return "0" + param;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.audio.play();
    })
  }
}
</script>
<style scoped lang="less">
.history-title {
  font-size: 20px;
  color: @recommendMusicianListTextColor;
}

.history-item {
  height: 270px;
  overflow-y: auto;
  line-height: 30px;
  color: @recommendMusicianListTextColor;


  .item-list {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .song-img {
      width: 40px;
      height: 40px;
      border-radius: 6px;
    }

    .song-title {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }

    .song-del {
      cursor: pointer;
    }
  }

}

.history-item::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>

<style scoped lang="less">
* {
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
}

.music-player {
  width: 100%;
  margin-top: 37px;

  .player-wrap {
    width: 62.5%;
    min-width: 1100PX;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex: 1;

      .img {

        width: 101px;
        height: 101px;
        border-radius: 4px;
        margin-top: -27px;

      }

      .info {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-size: 14px;
          color: @recommendMusicianListTextColor;
          font-weight: 500;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .des {
          font-size: 14px;
          color: @demoitemsongMusicianColor;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .audio {
      display: flex;
      // flex: 1;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 10px;

      ::v-deep .el-slider__runway {
        height: 4px;

        .el-slider__bar {
          height: 4px;
        }
      }

      ::v-deep.el-slider__button-wrapper {
        height: 20px;
        width: 20px;
        top: -10px;

        .el-slider__button {
          width: 12px;
          height: 12px;
        }
      }

      .musicPlay {
        width: 100%;
        // margin-top: 20%;
        display: flex;
        justify-content: center;

        // margin-bottom: 400px;
        .sliderClass,
        .audioControl {
          position: relative;
          display: flex;
        }

        ::v-deep .sliderClass .el-slider__button {
          width: 0;
          height: 0;
          border: none;
        }

        .audioControl {
          // position: fixed;
          // left: 15%;
          // bottom: 0%;
          width: 100%;
          // height: 250px;
          border-radius: 2px;

          // padding: 30px;
          // background-color: rgba(0, 0, 0, 0.4);
          .errorMask {
            color: #e6a23c;
            text-align: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
          }

          .time-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
              color: @recommendMusicianListTextColor;
              width: 120px;
              text-align: center;
            }
          }

          .audioClose {
            position: absolute;
            top: -10%;
            right: 3%;
            font-size: 25px;
          }

          p {
            color: white;
            // margin: 30px 0;
          }

          .playControl {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: center;
            user-select: none;
            margin-top: 8px;
            flex: auto;

            .playControlWrap {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .audioSpan,
            .audioVolSpan {
              color: white;
              font-size: 20px;
              margin-right: 30px;
              cursor: pointer;
              width: 80px;
              align-items: center;
              justify-content: flex-end;
              display: flex;

              &:hover {
                color: #409eff;
              }

              .audioIcon {
                font-size: 28px;
              }

              .speed {
                font-size: 16px;
              }

              .volume {
                font-size: 18px;
              }
            }

            .audioVolSpan {
              margin-right: 10px;
              margin-left: 32%;
            }

            .sliderVolumClass {
              display: inline-block;
              width: 25%;

              .el-slider__runway {
                margin: 0;
              }
            }
          }

          .voiceVolWrap {
            width: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }

    .right {
      display: flex;
      flex: 1;
      align-items: center;
      padding-top: 14px;
      //justify-content: space-between;
      .shengyin {
        cursor: pointer;
      }

      .history-data {
        width: 200px;
        height: 300px;
        overflow-y: scroll;
      }

      .history {
        margin: 0 24px;
        cursor: pointer;
      }

      .buy {
        width: 88px;
        height: 36px;
        background: #3370FF;
        border-radius: 18px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}
</style>
