import { render, staticRenderFns } from "./MusicPlayer.vue?vue&type=template&id=58e3a72f&scoped=true"
import script from "./MusicPlayer.vue?vue&type=script&lang=js"
export * from "./MusicPlayer.vue?vue&type=script&lang=js"
import style0 from "./MusicPlayer.vue?vue&type=style&index=0&id=58e3a72f&prod&scoped=true&lang=less"
import style1 from "./MusicPlayer.vue?vue&type=style&index=1&id=58e3a72f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e3a72f",
  null
  
)

export default component.exports